<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card :header="$t('Outlet selection')" class="mt-4">
          <b-table-simple class="border-0" bordered v-if="selected != null && blocked != null">
            <tbody>
              <template v-for="(outlets, catName, cat_index) in outletCategories">
                <tr :key="'spacer_' + catName" style="border: none;" v-if="cat_index > 0">
                  <td style="border: none"></td>
                </tr>
                <tr :key="'cat_' + catName" v-if="outlets.length > 0">
                  <td colspan="2">
                    <page-snippet :name="catName" /> 
                  </td>
                  <td class="align-bottom">
                    <b-button-group class="" v-if="outlets.length > 1 && catName !== 'rights_management_services'">
                      <b-button class="text-nowrap" :variant="'success'" @click="categorySetAll(catName, true)">{{ $t('Select all') }}</b-button>
                      <b-button class="text-nowrap" :variant="'danger'" @click="categorySetAll(catName, false)">{{ $t('Deselect all') }}</b-button>
                    </b-button-group> 
                  </td>
                </tr>
                <template v-for="outlet in outlets">
                  <tr :key="'ol_' + outlet.JsonValue">
                    <td>
                      <img v-if="outlet.ImageUrl" v-bind:class="{ 'portal-logo': true, disabled: !selected.includes(outlet.JsonValue) }" :src="outlet.ImageUrl" :alt="outlet.Value" />
                    </td>
                    <td>
                      <h3>{{ outlet.Value }}</h3>
                      <p>{{ outlet.Description }}</p>
                    </td>
                    <td>
                      <b-button-group v-if="!blocked.includes(outlet.JsonValue)">
                        <b-button class="text-nowrap" :variant="selected.includes(outlet.JsonValue) ? 'success' : 'secondary'" @click="toggle(outlet.JsonValue)">{{ $t('Available') }}</b-button>
                        <b-button class="text-nowrap" :variant="!selected.includes(outlet.JsonValue) ? 'danger' : 'secondary'" @click="toggle(outlet.JsonValue)">{{ $t('Not Available') }}</b-button>
                      </b-button-group>

                      <page-snippet name="outlet_blocked" v-if="blocked.includes(outlet.JsonValue)" /> 
                    </td>
                  </tr>
                  <tr :key="'ol_beatport' + outlet.JsonValue" v-if="outlet.JsonValue == 'Beatport' && selected.includes('Beatport')">
                    <td></td>
                    <td colspan="2">
                      <page-snippet name="beatport-label" class="" />
                    </td>
                  </tr>
                  <tr :key="'ol_youtube' + outlet.JsonValue" v-if="outlet.JsonValue == 'YouTube_ContentID' && selected.includes('YouTube_ContentID')">
                    <td></td>
                    <td colspan="2">
                      <field 
                        :defs="defs"
                        name="Attributes.YT_MATCH_POLICY"
                        :model="release" />
                      <page-snippet name="YouTube_ContentID" class="" />
                    </td>
                  </tr>
                  <tr :key="'ol_facebook' + outlet.JsonValue" v-if="outlet.JsonValue == 'FacebookAll' && selected.includes('FacebookAll')">
                    <td></td>
                    <td colspan="2">

                      <field 
                        :defs="defs"
                        name="Attributes.RightsClaimPolicyType"
                        :model="release" />
                        
                      <page-snippet name="facebook" class="" />
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </b-table-simple>
        </b-card>

        <b-alert show v-for="(error, index) in errors" :key="index" class="mt-4" variant="danger">
          {{ error }}
        </b-alert>

        <b-button :disabled="errors.length > 1" @click="$emit('proceed')" class="mt-4">
          {{ $t('Proceed with next step') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ReleaseConceptHelper from '@/mixins/ReleaseConceptHelper'

  export default {
    name: 'Outlets',
    components: {
      'field': () => import('@/components/ReleaseConceptField'),
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    mixins: [
      ReleaseConceptHelper
    ],
    data () {
      return {
        loaded: false,
        defs: this.$root.$data.fieldDefs.Album,
        field: 'Deliver to',
        selected: [],
        blocked: null,
        virtualOutlets: {
          FacebookAll: ['Facebook_AudioLibrary', 'Facebook_FingerPrinting'],
        }
      }
    },
    props: {
      release: Object,
    },
    watch: {
      release () {
        //console.log("Release changed, load selected")
        //this.loadSelected()
      },
      selected () {
        let names = [...this.selected]

        for (let vo_name in this.virtualOutlets) {
          const vo = this.virtualOutlets[vo_name]
          if (names.includes(vo_name)) {
            names = names.filter(name => name !== vo_name)
            names = names.concat(vo)
          }
        }

        let newAttrs = names.map(outlet => {
          return { Type: this.field, Value: outlet+':True'}
        })

        if (!names.includes('Future'))
          newAttrs.push({ Type: this.field, Value: 'Future:False' })

        if (this.release.Attributes) {
          this.release.Attributes.forEach(attr => {
            // Only re-add attributes other than current attribute
            if (attr.Type != this.field)
              newAttrs.push(attr)
          })
        }

        this.$set(this.release, 'Attributes', newAttrs)
      },
    },
    methods: {
      loadSelected () {
        if (!this.release.Attributes) {
          this.release.Attributes = []
        }

        let names = []
        this.release.Attributes.forEach(attr => {
          if (attr.Type == this.field && attr.Value.indexOf(':True') != -1) {
            names.push(attr.Value.substring(0, attr.Value.length - 5))
          }
        })

        for (let vo_name in this.virtualOutlets) {
          const vo = this.virtualOutlets[vo_name]
          let found = names.filter(name => vo.includes(name))

          if (found.length == 0) continue
          
          if (found.length !== vo.length) {
            this.errorMessage = this.$t('Invalid setting for outlet. Please contact support')
          }

          names = names.filter(name => !vo.includes(name)) 
          names.push(vo_name)
        }

        this.selected = names

        this.loaded = true
      },
      categorySetAll (catName, value) {
        for (let outlet of this.outletCategories[catName]) {
          this.toggle(outlet.JsonValue, value)
        }
      },
      toggle (outlet, value = null) {
        let i = this.selected.indexOf(outlet);
        if (i == -1 && value != false) {
          if (outlet === 'FacebookAll') {
            //this.$notify('Facebook was activated, please review Claim policy')
          }
          if (outlet === 'YouTube_ContentID') {
            //this.$notify('YouTube ContentID was activated, please review ContentID policy')
          }
          this.selected.push(outlet)
        } else if (i != -1 && value != true) {
          this.selected.splice(i, 1)
        }
      },
      proceed () {
        this.$emit('proceed')
      },
    },
    computed: {
      errors () {
        const errors = []

        if (this.selected.length == 0) {
          errors.push(this.$t('Select at least one outlet to proceed'))
        }

        if (
          this.selected.includes('YouTube_ContentID') && 
          !this.release.Attributes.find(attr => attr.Type == 'YT_MATCH_POLICY')
        ) {
          errors.push(this.$t('Youtube ContentID Policy is required.'))
        }

        if (
          this.selected.includes('FacebookAll') && 
          !this.release.Attributes.find(attr => attr.Type == 'RightsClaimPolicyType')
        ) {
          errors.push(this.$t('Facebook Claim Policy is required.'))
        }

        return errors
      },
      outletCategories () {
        let outlets = this.$root.$data.fieldDefs.Album['Attributes.Deliver to'].Options.filter(o => {
          let genre = this.getTag(this.release, 'MainGenre')
          if (genre != 'Classical' && ['Naxos', 'Primephonic'].includes(o.JsonValue)) return false
          if (genre != 'Dance' && ['Traxsource', 'Juno', 'Beatport'].includes(o.JsonValue)) return false
          return true
        })

        let cats = {}
        for (let outlet of outlets) {
          if (outlet.Category === null) outlet.Category = 'other_services'
          if (!cats[outlet.Category]) {
            cats[outlet.Category] = []
          }
          cats[outlet.Category].push(outlet)
        }

        return cats
      },
      readyToProceed () {
        return true
      }
    },
    mounted () {
      this.loadSelected();

      this.$http
        .get('sub-providers/' + this.release.SourceSubProviderID)
        .then(response => {
          this.blocked = response.data.BlockedServices
        })
    }
  }
</script>

<style lang="scss" scoped>
  .portal-logo {
    max-width: 100px;
    max-height: 75px;
  }

  img.disabled {
    opacity: 0.5;
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: grayscale(100%); /* Current draft standard */
  }
</style>